/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../../../style/global.module.css");

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 14px;
  min-height: 14px;
  box-shadow: var(--box-shadow-dark);
  font-weight: var(--font-weight-light);
  border-radius: var(--radius-lg);
}

.round {
  width: 14px;
  height: 14px;
}

.default {
  padding: var(--spacing-1) var(--spacing-2);
}

.defaultText {
  font-size: x-small;
  line-height: var(--spacing-2);
}

.largeText {
  font-size: var(--label-size);
  line-height: var(--spacing-4);
}

/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../style/app.module.css");

/* css overwritting web-utils components for Clive's changes */

.moButton{
    position : relative;
    width: 200px;
    justify-content: center;
    background-color: #434e5b;
    color: var(--color-primary); /* #ffc200 */
    border: var(--border-primary); /* 1px solid #ffc200 */
    border-radius: var(--radius-lg); /* 20px */
    transition: box-shadow 0.3s ease;
}

.moButton:hover,
.moButton:active,
.moButton:focus,
.moButton:visited {
  background-color: var(--color-primary); /* #ffc200 */
  color: var(--color-dark); /* #252c38  */
  box-shadow: var(--box-shadow-primary); /* 0 0 5px rgb(255 194 0 / 100%); */
}

.active{
  border:var(--border-primary);
  box-shadow: var(--box-shadow-primary);
  transform: scale(1.05);
}

.btnNavigation{
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-lg);
  transition: box-shadow 0.3s ease;
  color:#434e5b;
  border: 1px solid #434e5b;
}

.btnNavigation span svg{
  fill: #434e5b;
  stroke: #434e5b;

}

.btnNavigation:hover{
  background-color: #434e5b;
  color:var(--color-light);
  box-shadow: 0 0 15px rgb(67 78 91 / 100%);
}

.btnNavigation:hover span svg{
  fill: var(--color-light);
  stroke: var(--color-light);
}

@media screen and (width >=576px) {
  .moButton {
    width: 200px;
  }
}
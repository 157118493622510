/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../style/app.module.css");

.root_container {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
}

.dashboard {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
}

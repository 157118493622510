/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("./global.module.css");

/* typography  */
p,
a,
label,
span {
  font-family: var(--font-family-base);
  font-size: var(--fs-p-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--lh-p);
  margin: 0;
}

h1 {
  font-family: var(--font-family-header);
  font-size: var(--fs-h1);
  line-height: var(--lh-h);
  font-weight: var(--font-weight-bold);
}

h2 {
  font-size: var(--fs-h2);
  font-family: var(--font-family-header);
  font-weight: var(--font-weight-medium);
  line-height: var(--lh-h);
  margin: 0;
}

h3 {
  font-size: var(--fs-h3);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-semibold);
  margin: 0;
}

h4 {
  font-size: var(--fs-p);
  font-family: var(--font-family-header);
  font-weight: var(--font-weight-medium);
  line-height: var(--lh-h);
  margin: 0;
}

h5 {
  font-family: var(--font-family-base);
  font-size: var(--fs-h5);
  text-transform: uppercase;
  font-weight: var(--font-weight-semibold);
  padding-left: var(--spacing-2);
  margin: 0;
}

a {
  color: var(--color-light);
  text-decoration: none;
}

a:hover {
  color: var(--color-primary);
}

em {
  display: block;
  font-weight: var(--font-weight-semibold);
  font-style: normal;
}

@media screen and (width >= 576px) {
  p,
  a,
  label,
  span {
    font-size: var(--fs-p);
  }
}

/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../../../style/global.module.css");

.link {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--radius-sm);
}

.link_item {
  display: flex;
  align-items: center;
}

.navbar_link {
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (width >= 768px) {
  .navbar_link {
    flex-grow: 1;
    height: 36px;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
}

/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../style/app.module.css");

.container {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-2);
  grid-column: 1 / span 2;
}

.info {
  color: var(--color-tertiary);
  font-size: var(--fs-p-small);
  text-align: center;
  padding-bottom: var(--spacing-2);
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (width >=576px) {
  .container {
    grid-column: 1;
  }
}

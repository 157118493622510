/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../../../style/global.module.css");

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sizing {
  padding-right: var(--spacing-2);
}

.background {
  background-color: var(--color-dark);
  box-shadow: var(--box-shadow-dark);
  border-radius: var(--radius-sm);
  padding: var(--spacing-base);
}

/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../../../style/global.module.css");

.heading1 {
  font-family: var(--font-family-header);
  font-size: var(--fs-h1);
  line-height: var(--lh-h);
  font-weight: var(--font-weight-bold);
}

.heading2 {
  font-size: var(--fs-h2);
  font-family: var(--font-family-header);
  font-weight: var(--font-weight-medium);
  line-height: var(--lh-h);
  margin: 0;
}

.heading3 {
  font-size: var(--fs-h3);
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--lh-h);
  margin: 0;
}

.paragraph {
  font-family: var(--font-family-base);
  font-size: var(--fs-p-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--lh-p);
  margin: 0;
}

.custom {
  /* Custom styles can be added here */
}

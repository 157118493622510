/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../style/app.module.css");

.container {
  position: relative;
}

.button button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--spacing-base);
}

.active{
  background-color: #434e5b !important;
  color: var(--color-light) !important;
}

.active span svg{
  fill: var(--color-light) !important;
  stroke: var(--color-light) !important;
}

.wrapper {
  display: flex;
  column-gap: var(--spacing-2);
}

.content {
  position: absolute;
  z-index: 1000;
  display: flex;
  width: 100%;
  min-width: 200px;
  background-color: var(--color-dark);
  box-shadow: var(--box-shadow-dark);
  border-radius: var(--radius-sm);
  flex-direction: column;
  gap: var(--spacing-2);
}

.bottom {
  top: 100%;
  right: 0;
}

.right {
  bottom: 0;
  left: 100%;
}

.over {
  bottom: 20px;
  right: 0;
}

/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../../../style/global.module.css");

.footer_container {
  width: 100%;
  min-height: 40px;
  padding: 0 var(--spacing-2);
}

.footer_wrapper {
  border-top: var(--border-border);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--spacing-2);
  padding: var(--spacing-2) 0;
}

.footer_wrapper span {
  opacity: 0.8;
  font-size: var(--fs-p-small);
}

.footer_links {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

@media screen and (width >= 768px) {
   .footer_container{
    height: 45px;
  }

  .footer_wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer_links {
    flex-direction: row;
    gap: var(--spacing-4);
  }
}

/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../../../style/global.module.css");

.mo_sys_button {
  padding: var(--spacing-1) var(--spacing-2);
  background-color: transparent;
  border-radius: var(--radius-sm);
  font-size: var(--fs-p);
  line-height: var(--lh-p);
  font-weight: var(--font-weight-light);
  text-decoration: none;
  transition: 0.2s all;
  display: flex;
  cursor: pointer;
}

.mo_sys_button:disabled {
  opacity: 0.65;
  color: var(--color-tertiary);
  border: var(--border-tertiary);
  pointer-events: none;
}

.mo_sys_button:disabled span svg {
  stroke: var(--color-tertiary);
}

.mo_sys_button:disabled:hover,
.mo_sys_button:disabled:focus{
  opacity: 0.85;
  background-color: transparent;
  color: var(--color-tertiary);
  cursor: not-allowed;
}

.md {
  text-transform: uppercase;
}

.sm {
  text-transform: capitalize;
}

.round {
  border-radius: var(--radius);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary {
  color: var(--color-primary);
  border: var(--border-primary);
}

.primary:hover,
.primary:active,
.primary:focus,
.primary:visited {
  background-color: var(--color-primary);
  color: var(--color-dark);
}

.primary:hover span svg,
.primary:active span svg,
.primary:focus span svg,
.success:hover span svg,
.success:active span svg,
.success:focus span svg {
  stroke: var(--color-dark);
}

.secondary {
  background-color: var(--color-secondary);
  color: var(--color-light);
  border: none;
  box-shadow: var(--box-shadow-dark);
}

.error {
  color: var(--color-error);
  border: var(--border-error);
}

.error:hover,
.error:active,
.error:focus,
.error:visited {
  background-color: var(--color-error);
  color: var(--color-light);
}

.success {
  color: var(--color-success);
  border: var(--border-success);
}

.success:hover,
.sucess:active,
.success:focus,
.success:visited {
  background-color: var(--color-success);
  color: var(--color-light);
}

.info {
  color: var(--color-info);
  border: var(--border-info);
}

.info:hover,
.info:active,
.info:focus,
.info:visited {
  background-color: var(--color-info);
  color: var(--color-light);
}

.error:hover span svg,
.sucess:hover span svg,
.info:hover span svg {
  stroke: var(--color-light);
}

.light {
  border: none;
  font-weight: var(--font-weight-light);
  color: var(--color-light);
  padding: 0;
}

.light:hover,
.light:active,
.light:focus,
.light:visited {
  color: var(--color-primary);
}

.light:hover span svg {
  stroke: var(--color-primary);
}

.light:disabled {
  border: none;
}

.primary:disabled:hover span svg,
.primary:disabled:active span svg,
.primary:disabled:focus span svg {
  stroke: var(--color-primary);
}

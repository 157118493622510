/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../style/app.module.css");

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: var(--spacing-2);
}

.hover {
  background-color: #434e5b;
  box-shadow: var(--box-shadow-dark);
  cursor: pointer;
}

.grid {
  width: 80%;
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-auto-rows: minmax(50px, 100%);
  align-items: center;
  padding: var(--spacing-2);
}

.flex {
  width: 100%;
  height: 100%;
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: var(--spacing-2);
}

.action {
  padding-right: var(--spacing-2);
}

@media screen and (width >=576px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(33.33%, 1fr));
    place-items: center flex-start;
  }
}
/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("./global.module.css");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  font-family: var(--font-family-base);
  background-color: var(--color-dark);
  color: var(--color-light);
}

ul li {
  list-style: none;
}

img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../style/app.module.css");

.nav{
  width: 100%;
  background-color: var(--color-light);
  box-shadow: var(--box-shadow-dark);
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-2);
  padding: 0 var(--spacing-4);
}

.logo {
  height: 100%;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mosys{
  padding-top: var(--spacing-2);
}

.container{
  flex-grow: 1;
  align-self: center;

}

.navbar {
   position: fixed;
   top: 0;
   right: 0;
   z-index: 1;
   height: 100vh;
   width: 100%;
   max-width: 300px;
   background-color: var(--color-light);
   display: flex;
   flex-direction: column;
   align-items: stretch;
   justify-content: flex-start;
   gap: var(--spacing-2);
   padding: var(--spacing-2) var(--spacing-5) var(--spacing-2) var(--spacing-2);
   transform: translateX(100%);
   transition: transform 0.3s ease-in-out;
   opacity: 0;
}

 .show {
   transform: translateX(0);
   opacity: 1;
 }

 .navItem{
   padding: 0 var(--spacing-2);
 }

 .toggler {
   cursor: pointer;
   min-height: 50px;
   display: flex;
   justify-content: flex-end;
   align-items: center;
 }


 .link{
  padding: var(--spacing-2) var(--spacing-4);
  display: flex;
  column-gap: var(--spacing-2);

 }

 .linkactive{
   background-color: #434e5b;
   color: var(--color-light) !important;;
 }

 .linkactive span svg{
  fill: var(--color-light) !important;
  stroke: var(--color-light) !important;
}

 .toggler button:hover span svg {
   stroke: #434e5b;;
 }

 .navItemContainer{
    min-width: 200px;
 }

 .padding{
  padding: var(--spacing-4);
 }

 .borderBottom{
    border-bottom: var(--border-border);
    padding-bottom: var(--spacing-2);
 }

 .alert {
   position: absolute;
   left: 14px;
   bottom: 50%;
 }

 .card {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: var(--spacing-base);
 }

 .item{
    height: 36px;
    align-content: center;
    padding: var(--spacing-2);
 }

@media screen and (width >=576px) {
  .nav{
    padding: 0 var(--spacing-10);
  }

  .subnav{
    width: 150px;
  }

  .toggler {
    display: none;
  }

  .navbar{
    opacity: 1;
    position: static;
    z-index: 1;
    height: auto;
    width: auto;
    max-width: none;
    transform: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
   background-color:transparent;
  }

  .navItem{
    padding: 0;
  }

  .alert {
    left: 22px;
  }

  .card {
    width: 250px;
  }
}
/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
:root {
  /* Color */
  --color-primary: #ffc200;
  --color-secondary: #2c333e;
  --color-tertiary: #6a7481;
  --color-light: #fff;
  --color-dark: #252c38;
  --color-success: #6fbe44;
  --color-info: #19b1ff;
  --color-error: #c23659;
  --color-warning: #ffc200;
  --color-border: #42423c;

  /* Typography */
  --font-family-header: "Ubuntu", sans-serif;
  --font-family-base: "Open Sans", sans-serif;
  --fs-p: 1rem;
  --fs-h1: 1.5rem;
  --fs-h2: 1.375rem;
  --fs-h3: 1.125rem;
  --fs-h5: 1.125rem;
  --lh-p: 1.5rem;
  --lh-h: 1.8rem;
  --label-size: 1rem;
  --fs-p-small: 0.875rem;
  --small: 0.75rem;
  --large: 1.125rem;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* Spacing */
  --spacing-base: 0.25rem;
  --spacing-1: calc(var(--spacing-base) * 1);
  --spacing-2: calc(var(--spacing-base) * 2);
  --spacing-3: calc(var(--spacing-base) * 3);
  --spacing-4: calc(var(--spacing-base) * 4);
  --spacing-5: calc(var(--spacing-base) * 5);
  --spacing-6: calc(var(--spacing-base) * 6);
  --spacing-7: calc(var(--spacing-base) * 7);
  --spacing-8: calc(var(--spacing-base) * 8);
  --spacing-9: calc(var(--spacing-base) * 9);
  --spacing-10: calc(var(--spacing-base) * 10);
  --spacing-11: calc(var(--spacing-base) * 11);
  --spacing-12: calc(var(--spacing-base) * 12);

  /* Border | Radius */
  --radius-sm: 3px;
  --radius-lg: 20px;
  --radius: 50%;
  --border-primary: 1px solid var(--color-primary);
  --border-primary-lg: 2px solid var(--color-primary);
  --border-tertiary: 1px solid var(--color-tertiary);
  --border-light: 1px solid var(--color-light);
  --border-error: 1px solid var(--color-error);
  --border-error-lg: 2px solid var(--color-error);
  --border-success: 1px solid var(--color-success);
  --border-info: 1px solid var(--color-info);
  --border-info-lg: 2px solid var(--color-info);
  --border-dark: 1px solid var(--color-dark);
  --border-form: 1px solid #ffdf80;
  --border-border: 1px solid var(--color-border);
  --border-border-lg: 2px solid var(--color-border);

  /* Box Shadow */
  --box-shadow: 0 0 0 0.1rem rgb(244 244 245 /30%);
  --box-shadow-primary: 0 0 15px rgb(255 194 0 / 100%);
  --box-shadow-dark: 0 3px 2px rgb(0 0 0 / 10%);
  --box-shadow-form: 0 0 0 0.25rem rgb(255 191 0 / 25%);
}

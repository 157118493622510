/* Copyright 2025 Mo-Sys Engineering Ltd. All Rights Reserved. */
@import url("../../../../../style/global.module.css");

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  border: var(--border-border);
  border-radius: var(--radius);
  text-shadow: 0 0 10px var(--color-primary);
  box-shadow: var(--box-shadow-dark);
}

.smallLoader {
  width: 20px;
  height: 20px;
}

.default {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
}

.loader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  border: 1px solid transparent;
  animation: animate-c 2s linear infinite;
}

.smallLoader::before {
  border-top: var(--border-primary);
  border-right: var(--border-primary);
}

.default::before {
  border-top: 3px solid var(--color-primary);
  border-right: 3px solid var(--color-primary);
}

.loading_text {
  font-family: var(--font-family-header);
  color: var(--color-primary);
  text-transform: uppercase;
}

@keyframes animate-c {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}
